class Modal extends Main{

	constructor(options) {
		super()
		this.modalPath = options.path;
		this.options = options.options;
		this.events();
	}



	events() {
		// выпадает автоматом
		if(this.options?.path) {
			this.target = this.options?.path;
			this.animation = 'fadeInUp';
			this.speed = '2000';
			this.orderOpen();
		}

		// выпадает при клике
		document.addEventListener('click', function(e){
			const clickedElement = e.target.closest('[data-path]');


			if (clickedElement) {
				this.clickedElement = clickedElement;
				this.target = this.clickedElement.dataset.path;
				let animation = this.clickedElement.dataset.animation;
				let speed = this.clickedElement.dataset.speed;
				this.animation = animation ? animation : 'fadeInUp';
				this.speed = speed ? parseInt(speed) : '1000';

				this.orderOpen();
			}

			if (e.target.dataset.close) {
				this.closeModal = e.target.dataset.close;
				this.close();
			}

		}.bind(this));

		window.addEventListener('keydown', function(e) {
			if (e.keyCode == 27) {
				if (this.isOpen) {
					this.close();
				}
			}
		}.bind(this));

	}

	async setHtmlModal() {
		this.sendData = this.target
		this.url = this.options?.fetchUrl;
		let data = await this.sendRequest();
		let {modal} = data
		document.body.insertAdjacentHTML('beforeend', modal)
	}

	openModal() {
		let open = () => {
			this.disableScroll();
			this.modal.classList.add('is-open');
			this.modalContainer.classList.add('modal-popup-open');

			setTimeout(() => {
				this.modalContainer.classList.add('animate-open');
			}, 0);
		}

		if(this.options?.timeout) {
			setTimeout(() => {
				open();
			}, this.options.timeout);
		} else {
			open();
		}


	}

	async orderOpen() {

		if(!this.options?.inDom) await this.setHtmlModal();


		this.modal = document.querySelector(`[data-target="${this.target}"]`);
		this.modalContainer = this.modal.querySelector(`.modal-popup__container`);

		this.modal.style.setProperty('--transition-time', `${this.speed / 1000}s`); // устанавливаем скорость анимации
		this.modalContainer.classList.add(this.animation); // устанавливаем анимацию


		this.openModal();

	}

	disableScroll() {
		let paddingOffset = window.innerWidth - document.body.offsetWidth + 'px'
		document.body.classList.add('disable-scroll');
		document.body.style.paddingRight = paddingOffset;
	}

	enableScroll() {
		document.body.style.paddingRight = '0';
		document.body.classList.remove('disable-scroll');
	}

	close() {
		const modal = document.querySelector(`[data-target="${this.closeModal}"]`);
		const modalContainer = modal.querySelector(`.modal-popup__container`);

		modalContainer.classList.remove('animate-open');
		modal.classList.remove('is-open');

		this.enableScroll();
		setTimeout(() => {
			modal.remove();
		}, this.speed);
	}
}



const modal = new Modal({

	options: {
		// если в дом дереве
		// inDom: true,
		// path: 'two',
		// timeout: '1000',
		// path: 'first',
		// cssPath: null,
		fetchUrl: '/wp-content/themes/web-nn/ajax/modal.php',
	},

});


class Form extends Main{

	constructor(options) {
		super()
		this.events();
	}

	events() {
		document.addEventListener('click', function(e){
			e.preventDefault();
			this.element = e.target;

			// btn
			if (this.element.dataset.form) {
				this.form = this.element.closest('form');
				this.validate();
			}

			// placeholder
			if (this.element.classList.contains('placeholder-container__input')) {
				let caption = e.target.parentElement.querySelector('.caption')
				caption.classList.add('caption__active')
			}

		}.bind(this));

	}

	validate() {
		this.necessarily = this.form.querySelectorAll('._necessarily');
		this.error = 0;

		let addError = input => {
			input.classList.add('error');
			input.style.border = '1px solid red';
			this.error++;
		}

		let removeError = input => {
			input.classList.remove('error');
			input.style.border = '';
		}

		this.necessarily.forEach((item) => {
			removeError(item)
			if (item.value === '') addError(item);
			if (item.dataset.value === '0') addError(item);
		});

		if(this.error === 0) this.getData()

	}

	getData() {
		this.formData = [...new FormData(this.form)]; // данные формы
		this.formData.push(['link', `${document.location.href}`]); // ссылка

		// доп данные
		this.dataValue = this.form.querySelectorAll(`[data-value]`);
		this.dataValue.forEach(item => {
			let name = item.getAttribute('name');
			let value = item.dataset.value;
			if(name === 'dataForm') {
				let arVal = value.split("::");
				arVal.forEach(item => {
					let subValue = item.split("=");
					if(subValue[0] === 'target') this.yaTarget = subValue[1];
					this.formData.push([`${subValue[0]}`, `${subValue[1]}`]);
				});
				return
			}
			this.formData.push([`${name}`, `${value}`]);
		});

		// данные из localStorage
		// let localData = JSON.parse(localStorage.getItem('arrItems'));
		// console.log(this.formData);

		this.submitForm();
	}

	async submitForm() {
		this.url = `/wp-content/themes/web-nn/ajax/phpmailer/send.php`;
		this.sendData = Object.fromEntries(this.formData);
		let data = await this.sendRequest();
		alert('Письмо успешно отпралено!')
		if(this.yaTarget) console.log(this.yaTarget);
	}

}

new Form({ options: {}, });

// select
document.addEventListener('click', function (e) {
	var element = e.target;

	if (element.classList.contains('select__input')) {
		var $select = element.closest('.select');

		if ($select.classList.contains('open')) {
			$select.classList.remove('open');
		} else {
			setTimeout(function () {
				var $selectOpen = document.querySelectorAll('.open');

				if ($selectOpen) {
					$selectOpen.forEach(function (item) {
						item.classList.remove('open');
					});
				}

				$select.classList.add('open');
			}, 10);
		}
	}

	if (element.classList.contains('select__item')) {
		var _$select = element.closest('.select');

		_$select.classList.remove('open');

		var $selectInput = _$select.querySelector('.select__input');

		$selectInput.textContent = element.textContent;
		$selectInput.dataset.value = element.textContent;
	}
});

